.content {
    p, li {
        line-height: $line-height_content;
    }

    li {
        margin-bottom: 1rem;
    }

    ol {
        list-style: none;
        counter-reset: counter;

        li {
            counter-increment: counter;

            &:before {
                @include font(16px,600);
                content: counter(counter)'. ';
                color: $color__brand;
            }
        }
    }
}