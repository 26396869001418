.intro {
    @extend %zi-2;
    @extend %flex-column;
    justify-content: center;

    @include media-breakpoint-up(lg) {
        @include pos(0,0);
        right: 0;
        bottom: 0;
    }
}

.intro__title {
    margin: 0 0 20px;
    color: #fff;
    font-weight: 700;

    @include media-breakpoint-up(xl) {
        @include fs-lh(46px,48px);
    }

    @include media-breakpoint-between(sm,lg) {
        @include fs-lh(38px,40px);
    }

    @include media-breakpoint-down(md) {
        text-align: center;
    }

    @include media-breakpoint-only(xs) {
        @include fs-lh(24px,32px);
    }
}

.intro__subtitle {
    color: #fff;
    letter-spacing: 0.03em;
    line-height: $line-height_content;

    @include media-breakpoint-down(md) {
        margin: 0 0 45px;
        text-align: center;
    }

    @include media-breakpoint-up(sm) {
        @include fs-lh(24px,30px);
    }

    @include media-breakpoint-only(xs) {
        @include fs-lh(18px,24px);
    }
}