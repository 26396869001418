.section {
    overflow: hidden;
    background: #fff;

    @include media-breakpoint-up(xl) {
        margin-bottom: 80px;
    }

    @include media-breakpoint-only(lg) {
        margin-bottom: 60px;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 45px;
    }

    &.section_bg {
        position: relative;
        background-size: cover;
        background-color: $color__bg;
        background-repeat: no-repeat;
        background-position: center top;

        @include media-breakpoint-up(xl) {
            @include py(80px);
        }

        @include media-breakpoint-only(lg) {
            @include py(60px);
        }

        @include media-breakpoint-down(md) {
            @include py(45px);
        }
    }
}

.section__title {
    @extend %title-default;
    @include before;
    text-align: center;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
        @include offset(0 0 50px,0 0 20px);
    }

    @include media-breakpoint-down(sm) {
        @include offset(0 0 32px,0 0 20px);
    }

    &:before {
        @include wh(50px,1px);
        left: 50%;
        bottom: 0;
        margin: 0 0 0 -25px;
        background: $color__brand;
    }

    &.section__title_light {
        color: #fff;
    }
}

.section__bg {
    @include before;
    @include pos(0,0);
    right: 0;
    bottom: 0;
    z-index: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    background-color: rgba(0,0,0,0);

    &:before {
        @include pos(0,0);
        right: 0;
        bottom: 0;
        background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,0.9) 60%, rgba(255,255,255,0.1) 100%);
        background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.9) 60%,rgba(255,255,255,0.1) 100%);
        background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.9) 60%,rgba(255,255,255,0.1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#1affffff',GradientType=1 );                    
    }
}