.social {
    @extend %flex-center;
}

.social__item {
    @include fw(20px);
    @include wh(20px,20px);
    margin: 0 10px 0 0;
    color: #fff;

    &:hover {
        color: #fff !important;
    }
}