.rows {
    display: flex;
    line-height: $line-height_content;

    @include media-breakpoint-up(lg) {
        @include py(16px);
        align-items: center;
    }

    @include media-breakpoint-down(md) {
        @include py(0,24px);
        flex-wrap: wrap;
    }

    &:nth-child(2n) {
        background: #fff;
    }

    &:nth-child(2n+1) {
        background: $color__bg;
    }

    &.rows_title {
        color: #fff;
        background: $color__brand_light;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &:hover {
        .rows__col_invisible {
            opacity: 1;
            pointer-events: all;
        }
    }
}

.rows__col {
    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: 0 0 16px;
    }

    &.rows__col_title {
        @include font(16px,600);
    }

    &.rows__col_invisible {
        text-align: center;
        transition: all .3s ease;

        @include media-breakpoint-up(lg) {
            opacity: 0;
            pointer-events: none;
        }

        @include media-breakpoint-down(md) {
            display: block;
            margin-bottom: 0;
        }
    }
}

.rows__title {
    @include offset(0 -15px 8px,8px 15px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    color: #fff;
    background: $color__brand_light;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}