%d-flex {
    display: flex;
    flex-wrap: wrap;
}

%flex-center {
    display: flex;
    align-items: center;
}

%flex-column {
    display: flex;
    flex-direction: column;
}

%btn-default {
    @include inline-block;
    border: 0;
    outline: 0;
    padding: 0;
    cursor: pointer;
    background: none;

    .zi-2 {
        pointer-events: none;
    }

    .icon-fluid {
        pointer-events: none;
    }
}

%link-default {
    text-decoration: none;
    color: $color__link;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $color__link;
    }
}

%title-default {
    color: $color__title;
    font-weight: bold;
    line-height: $line-height_content;

    @include media-breakpoint-up(md) {
        font-size: 30px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 24px;
    }
}

%text-default {
    @include font($font-size_default,300);
    line-height: 22px;
}

%color-default {
    color: $color__default;

    &:hover,
    &:focus {
        color: $color__default;
    }
}

%color-brand {
    color: $color__brand;

    &:hover,
    &:focus {
        color: $color__brand;
    }
}

%zi-1 {
    z-index: 1;
    position: relative;
}

%zi-2 {
    z-index: 2;
    position: relative;
}