.promo {
    position: relative;

    @include media-breakpoint-up(xl) {
        margin: 0 0 80px;
    }

    @include media-breakpoint-only(lg) {
        margin: 0 0 60px;
    }

    @include media-breakpoint-down(md) {
        @include offset(0 0 45px,45px 0);
    }
}

.promo__wrapper {
    @include media-breakpoint-down(md) {
        @include pos(0,0);
        right: 0;
    }

    &.promo__wrapper_noslides {
        @include media-breakpoint-down(md) {
            bottom: 0;
        }

        .promo__slide {
            @include media-breakpoint-down(md) {
                height: 100%;
            }
        }
    }
}

.promo__slide {
    @include media-breakpoint-up(lg) {
        background-image: none;
    }

    @include media-breakpoint-down(md) {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center top;
        background-color: rgba(0,0,0,0);
    }

    .img-fluid {
        @include media-breakpoint-down(md) {
            visibility: hidden;
        }
    }
}