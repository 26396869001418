.pricing {
    @extend %flex-column;
    height: 100%;
    position: relative;
    text-align: center;
    border: 1px solid $color__border;

    margin: 43px 0 0;

    @include media-breakpoint-up(sm) {
        padding: 60px 30px 65px;
    }

    @include media-breakpoint-only(xs) {
        padding: 60px 15px 45px;
    }
}

.pricing__label {
    @extend %flex-column;
    @include wh(86px,86px);
    position: absolute;
    top: -43px;
    right: 30px;
    color: #fff;
    align-items: center;
    justify-content: center;

    .pricing__icon {
        @include pos(0,0);
        z-index: 1;
        color: $color__brand;
    }
}

.pricing__header {
    @include offset(0 0 40px,0 0 40px);
    @include border(0 0 1px,$color__border);
}

.pricing__title {
    @include font(20px,700);
    margin: 0 0 32px;
    text-transform: uppercase;
}

.pricing__subtitle {
    @include font(16px,600);
    margin: 0 0 16px;
    color: lighten($color__default,30%);
}

.pricing__meta {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-only(xs) {
        flex-direction: column;
    }
}

.pricing__price {
    @include font(24px,600);
    color: $color__brand;

    &.pricing__price_old {
        font-weight: 400;
        color: lighten($color__default,50%);
        text-decoration: line-through;

        @include media-breakpoint-up(sm) {
            margin: 0 15px 0 0;
        }

        @include media-breakpoint-only(xs) {
            margin: 0 0 15px;
        }
    }
}

.pricing__feature {
    @extend %flex-center;
    justify-content: center;
    margin: 0 0 16px;
    line-height: $line-height_content;

    .pricing__icon {
        margin: 0 15px 0 0;
        color: $color__brand;
    }
}

.pricing__icon {
    @include fw(20px);
    @include wh(20px,20px);

    &.pricing__icon_lg {
        @include mw(86px);
        @include wh(86px,86px);
    }
}

.pricing__body {
    @extend %flex-column;
    justify-content: space-between;
    flex-grow: 1;
}

.pricing__text {
    margin: 0 0 32px;
    line-height: $line-height_content;
}

.pricing__footer {

}