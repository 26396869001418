.header {
    @include border(0 0 1px,$color__border);

    @include media-breakpoint-up(lg) {
        padding: 30px 0;
    }

    @include media-breakpoint-down(md) {
        padding: 15px 0 0;
    }
}

.header__contacts {
    @include media-breakpoint-down(md) {
        order: 3;
        flex-grow: 1;
        padding: 15px 0;
    }
}