$font-family_default: 'Montserrat', Arial, sans-serif;
$font-family_title: $font-family_default;
$font-size_default: 14px;

$line-height_default: 1;
$line-height_content: 1.4;

$color__brand: rgba(221,32,62,1);
$color__brand_light: rgba(221,32,62,0.5);
$color__border: #ececee;
$color__danger: #d9534f;

$color__bg: #f8f8f8;

$color__default: #222;
$color__title: $color__default;
$color__link: $color__default;