.card {
    @extend %flex-column;
    height: 100%;
    text-align: center;
    background: #fff;
}

.card__picture {
    margin: 0 0 24px;
}

.card__title {
    @extend %flex-column;
    @include font(16px,700);
    line-height: $line-height_content;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 0 16px 24px;
}