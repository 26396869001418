.form {
    &.form_bg {
        background: $color__brand_light;

        @include media-breakpoint-up(sm) {
            padding: 30px;
        }
    
        @include media-breakpoint-only(xs) {
            padding: 20px 15px;
        }

        .form__title {
            color: #fff;
        }

        .form__label {
            color: #fff;
        }

        .form__footer {
            color: #fff;
        }
    }
}

.form__title {
    @include font(24px,700);
    margin: 0 0 20px;
    position: relative;
    letter-spacing: 0.03em;
    text-transform: uppercase;
}

.form__item {
    margin: 0 0 20px;
    transition: all .3s ease;

    &.form__item_row {
        @extend %flex-center;
        padding: 0 0 0 10px;
        background: #fff;
        border: 1px solid #fff;

        .form__field {
            flex-grow: 1;
        }
    }

    &.form__item_error {
        background: $color__brand_light;
        
        .form__field {
            color: #fff;
            background: none;
        }

        .form__icon {
            color: #fff;
        }
    }
}

.form__field {
    @include wh(100%,45px);
    @include fs-lh(14px,22px);
    border: 0;
    padding: 10px;
    background: #fff;
    transition: all .3s ease;
}

.form__toggler {
    display: none;

    &.form__toggler_checkbox {
        & + .form__label {
            @extend %flex-center;
            cursor: pointer;
        }

        &:checked + .form__label {
            .icon-fluid {
                display: block;
            }
        }
    }
}

.form__checked {
    @include fw(18px);
    @include wh(18px,18px);
    @include offset(0 10px 0 0,2px);
    color: $color__default;
    background: #fff;

    .icon-fluid {
        display: none;
    }
}

.form__icon {
    @include fw(24px);
    @include wh(24px,24px);
    margin: 0 10px 0 0;
    color: $color__brand;
    transition: all .3s ease;
}

.form__close {
    @include wh(auto,auto);
    @include fs-lh(36px,1);
    position: absolute;
    top: -6px;
    right: 0;
    color: #fff;
}

.form__footer {
    @include fs-lh(12px,14px);
}

.form__tab {
    display: none;

    &.active {
        display: block;
    }
}