.divider {
    @include py(32px);
    color: #fff;
    background: $color__brand_light;

    @include media-breakpoint-up(xl) {
        margin-bottom: 80px;
    }

    @include media-breakpoint-down(lg) {
        margin-bottom: 60px;
    }
}

.divider__icon {
    @include wh(86px,86px);

    @include media-breakpoint-up(sm) {
        @include fw(86px);
        margin: 0 32px 0 0;
    }

    @include media-breakpoint-only(xs) {
        margin: 0 0 32px;
    }
}

.divider__text {
    @include font(20px,600);
    flex-grow: 1;
    line-height: $line-height_content;

    @include media-breakpoint-only(xs) {
        text-align: center;
    }
}