.nav {
    @include border(0 0 1px,$color__border);
    position: relative;
    z-index: 3;
    transition: all .3s ease;

    &.nav_affix {
        @include pos(0,0,fixed);
        right: 0;
        z-index: 1030;
        background: #fff;

        @include media-breakpoint-up(lg) {
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.15);
        }

        .nav__inner {
            @include media-breakpoint-down(md) {
                box-shadow: 0 0 10px 0 rgba(0,0,0,0.15);
            }
        }
    }
}

.nav__toggler {
    @extend %zi-2;
    @extend %btn-default;
    background: #fff;

    @include media-breakpoint-up(lg) {
        display: none;
    }

    @include media-breakpoint-down(md) {
        @include fs-lh(18px,22px);
        display: block;
        width: 100%;
        padding: 10px 0;
        text-transform: uppercase;
    }

    .nav__arrow {
        @include media-breakpoint-down(md) {
            @include fw(24px);
            @include wh(24px);
            @include offset(0,0);
            position: static;
        }
    }
}

.nav__inner {
    @include media-breakpoint-down(md) {
        position: absolute;
        left: 0;
        right: 0;
        display: none;
        background: #fff;
    }

    &.active {
        @include media-breakpoint-down(md) {
            z-index: 1;
            height: 296px;
            display: block;
            overflow: auto;
        }
    }
}

.nav__item {
    position: relative;

    @include media-breakpoint-up(lg) {
        @include inline-block;
    }

    @include media-breakpoint-down(md) {
        display: block;
    }

    &.active> {
        .nav__link {
            @include media-breakpoint-down(md) {
                color: $color__brand;
                background: $color__bg;
            }
        }

        .nav__arrow {
            @include media-breakpoint-down(md) {
                transform: rotate(180deg);
            }
        }

        .nav__dropdown {
            @include media-breakpoint-down(md) {
                display: block;
            }
        }
    }

    &:hover {
        & > {
            .nav__link {
                color: $color__brand;
                background: $color__bg;
            }

            .nav__dropdown {
                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }
        }
    }
}

.nav__link {
    @include fs-lh(12px,16px);
    padding: 16px 14px;
    text-transform: uppercase;
    transition: all .3s ease;

    @include media-breakpoint-up(lg) {
        @include inline-block;
    }

    @include media-breakpoint-down(md) {
        display: block;
    }

    &.nav__link_category {
        @extend %flex-center;
        justify-content: space-between;
        padding-right: 28px;
    }

    &:hover,
    &.nav__link_current {
        color: $color__brand;
    }

    &:hover {
        @include media-breakpoint-up(lg) {
            background: $color__bg;
        }
    }
}

.nav__arrow {
    @extend %btn-default;
    position: absolute;
    right: 4px;

    @include media-breakpoint-up(lg) {
        @include fw(12px);
        @include wh(12px);
        top: 50%;
        margin: -6px 0 0 6px;
    }

    @include media-breakpoint-down(md) {
        @include fw(28px);
        @include wh(28px);
        top: 8px;
        padding: 8px;
    }
}

.nav__dropdown {
    display: none;
    background: #fff;

    @include media-breakpoint-up(lg) {
        @include border(1px,$color__border);
        position: absolute;
        left: 0;
        width: 240px;
    }

    .nav__item {
        display: block;

        .nav__dropdown {
            @include media-breakpoint-up(lg) {
                top: 0;
                left: auto;
                right: -240px;
            }

            .nav__link {
                @include media-breakpoint-down(md) {
                    padding: 10px 14px 10px 48px;
                }
            }
        }
    }

    .nav__link {
        display: block;

        @include media-breakpoint-up(lg) {
            padding: 12px 14px;
        }

        @include media-breakpoint-down(md) {
            padding: 10px 14px 10px 32px;
        }

        &.nav__link_category {
            display: flex;
        }
    }

    .nav__arrow {
        margin-top: 0;

        @include media-breakpoint-up(lg) {
            right: 4px;
            margin-top: -7px;
            transform: rotate(-90deg);
        }
    }
}