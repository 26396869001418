.logo {
    @extend %flex-center;

    @include media-breakpoint-up(sm) {
        @include fs-lh(16px,22px);
        margin-right: 20px;
    }

    @include media-breakpoint-only(xs) {
        @include fs-lh(14px,18px);
    }
}

.logo__icon {
    @include fw(60px);
    @include wh(60px);
    margin: 0 15px 0 0;
    color: $color__brand;
}

.logo__title {
    &.logo__title_inverse {
        color: #fff;

        .logo__bottom {
            color: #fff;
        }
    }
}

.logo__bottom {
    display: block;
    font-weight: 700;
    color: $color__brand;
}