.accordion {
    @include border(0 0 1px,$color__border);

    &.active {
        .accordion__title {
            &:before {
                transform: rotate(-180deg);
            }

            &:after {
                opacity: 0;
            }
        }

        .accordion__body {
            display: block;
        }
    }
}

.accordion__title {
    @extend %btn-default;
    @include before;
    @include font(20px,700);
    line-height: $line-height_content;
    text-transform: uppercase;
    text-align: left;
    width: 100%;
    display: block;
    padding: 32px 40px 32px 0;

    &:before {
        @include size(26px,2px);
        top: 46px;
        right: 0;
        background: $color__brand;
        transition: all .3s ease;
    }

    &:after {
        @include size(2px,26px);
        content: '';
        position: absolute;
        top: 34px;
        right: 12px;
        background: $color__brand;
        transition: all .3s ease;
    }
}

.accordion__body {
    @include fs-lh(13px,26px);
    padding: 0 0 32px;
    display: none;
}