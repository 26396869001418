.footer {
    color: #fff;
    background: $color__default;
}

.footer__top {
    @extend %d-flex;

    @include media-breakpoint-up(xl) {
        @include py(80px,60px);
    }

    @include media-breakpoint-only(lg) {
        @include py(60px,45px);
    }

    @include media-breakpoint-down(md) {
        @include py(45px);
    }
}

.footer__nav {
    @include media-breakpoint-up(sm) {
        margin: 0 0 25px;
    }

    @include media-breakpoint-only(xs) {
        margin: 0 0 50px;
    }
}

.footer__nav-link {
    color: #fff;
    display: block;
    margin: 0 0 20px;

    &:hover {
        color: #fff;
        text-decoration: underline;
    }
}

.footer__bottom {
    @include py(20px);
    @include fs-lh(12px,16px);
    background: #111;
}