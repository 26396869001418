.review {
    margin: 0 0 40px;
    text-align: center;
}

.review__picture {
    border-radius: 50%;
}

.review__text {
    @include fs-lh(16px,1.8);
    font-style: italic;
    margin: 0 0 16px;
}

.review__author {
    font-size: 18px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}