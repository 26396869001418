.member {
    display: block;
    text-align: center;
    margin: 0 0 32px;
}

.member__title {
    @include fs-lh(24px,$line-height_content);
    margin: 0 0 8px;
    display: block;
}

.member__subtitle {
    display: block;
    font-size: 16px;
    font-style: italic;
}