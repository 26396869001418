@import '~bootstrap/scss/bootstrap-reboot.scss';

@import 'tpl/variables';
@import 'tpl/mixins';
@import 'tpl/extends';
@import 'tpl/common';

@import '~bootstrap/scss/images';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/utilities/text';
@import '~bootstrap/scss/utilities/display';
@import '~bootstrap/scss/utilities/flex';
@import '~bootstrap/scss/utilities/spacing';

@import 'blocks/header';
@import 'blocks/logo';
@import 'blocks/nav';
@import 'blocks/promo';
@import 'blocks/intro';
@import 'blocks/feature';
@import 'blocks/pricing';
@import 'blocks/divider';
@import 'blocks/rows';
@import 'blocks/card';
@import 'blocks/member';
@import 'blocks/review';
@import 'blocks/content';
@import 'blocks/counter';
@import 'blocks/contacts';
@import 'blocks/accordion';
@import 'blocks/footer';

@import 'blocks/form';
@import 'blocks/social';
@import 'blocks/swiper';
@import 'blocks/section';