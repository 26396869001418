.counter {
    color: #fff;
    text-align: center;
}

.counter__icon {
    @include wh(48px);
    margin: 0 auto 16px;
}

.counter__title {
    margin: 0 0 8px;
    font-size: 16px;
    text-transform: uppercase;
}

.counter__value {
    @include font(40px,700);
    color: $color__brand;

    &.counter__value_pc {
        &:after {
            content: '%';
        }
    }
}