.contacts {
    display: flex;

    &.contacts_row {
        @include media-breakpoint-up(xl) {
            align-items: center;
        }

        @include media-breakpoint-only(lg) {
            flex-direction: column;
        }

        @include media-breakpoint-between(sm,md) {
            align-items: center;
            justify-content: space-between;
        }

        @include media-breakpoint-only(xs) {
            flex-direction: column;
        }

        .contacts__item {
            @include media-breakpoint-up(lg) {
                flex-grow: 1;
                margin: 0 25px 0 0;
            }

            @include media-breakpoint-only(lg) {
                margin-bottom: 10px;
            }

            @include media-breakpoint-only(xs) {
                justify-content: center;
                margin-bottom: 10px;
            }

            &:last-child {
                @include media-breakpoint-down(lg) {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.contacts_column {
        flex-direction: column;

        @include media-breakpoint-up(sm) {
            margin: 0 0 25px;
        }

        .contacts__item {
            margin: 0 0 15px;
        }

        .contacts__icon {
            @include fw(32px);
            @include wh(32px);
            @include offset(0 15px 0 0,4px 6px);
            border-radius: 50%;
            background: #fff;
        }
    }
}

.contacts__item {
    @extend %flex-center;

    &.contacts__item_inverse {
        color: #fff;
    }
}

.contacts__icon {
    @include fw(30px);
    @include wh(30px);
    margin: 0 10px 0 0;
    color: $color__brand;

    &.contacts__icon_inverse {
        @include fw(36px);
        @include wh(36px);
        color: #fff;
        padding: 6px;
        border-radius: 50%;;
        background: $color__brand;
    }
}