.feature {
    margin: 0 0 50px;
    text-align: center;

    &:hover {
        .feature__icon {
            &:before {
                transform: translateX(0);
            }
        }

        .feature__picture {
            transform: translateY(-2px);
            box-shadow: 0 5px 23px 0 rgba(0, 0, 0, 0.25);
        }
    }

    &.feature_row {
        display: flex;
        align-items: center;
        margin: 0 0 32px;

        &:last-child {
            margin-bottom: 0;
        }

        @include media-breakpoint-up(sm) {
            text-align: left;
        }
    
        @include media-breakpoint-only(xs) {
            flex-direction: column;
        }

        .feature__header {
            @include fw(86px);

            @include media-breakpoint-up(sm) {
                margin: 0 20px 0 0;
            }
        }

        .feature__content {
            flex-grow: 1;
        }

        .feature__title {
            text-transform: none;
        }
    }
}

.feature__header {
    margin: 0 0 20px;
}

.feature__picture {
    transition: 320ms ease-in-out;
}

.feature__icon {
    @include before;
    @include inline-block;
    @include wh(86px,86px);
    padding: 20px;
    color: #fff;
    overflow: hidden;
    border-radius: 50%;
    background: $color__brand;

    &:before {
        @include pos(0,0);
        right: 0;
        bottom: 0;
        z-index: 1;
        border-radius: 50%;
        transition: all .3s ease;
        transform: translateX(-100%);
        background: $color__default;
    }
}

.feature__title {
    @include font(18px,700);
    margin: 0 0 16px;
    text-transform: uppercase;
}

.feature__text {
    @include fs-lh(13px,26px);
}