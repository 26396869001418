body {
	font: $font-size_default $font-family_default;
	line-height: $line-height_default;
	color: $color__default;
    background: #fff;
}

h1 {
    @extend %title-default;
}

a {
    @extend %link-default;

    &:hover,
    &:focus {
        @extend %link-default;
    }
}

input {
    outline: 0;
    outline: none;
}

button {
    outline: 0;
    cursor: pointer;

    &:hover,
    &:focus {
        outline: 0;
        cursor: pointer;
    }
}

.color-brand {
    @extend %color-brand;
}

.border-radius {
    border-radius: 8px;
}

.btn {
    @extend %btn-default;
    padding: 12px 25px;
    color: #fff;
    text-transform: uppercase;
    border: 2px solid $color__brand;
    background: $color__brand;
    transition: all .3s ease 0s;

    &:hover {
        background: $color__default;
        border-color: $color__default;
    }

    &.btn_fw {
        width: 100%;
        display: block;
    }

    &.btn_inverse {
        background: $color__default;
        border-color: $color__default;
    }

    &.btn_nohover {
        &:hover {
            background: $color__brand;
            border-color: $color__brand;
        }
    }
}

.icons {
    display: none;
}

.zi-2 {
    @extend %zi-2;
}

.icon-fluid {
    @include size(100%,100%);
    pointer-events: none;
}

.icon-prev {
    @include size(18px,18px);
}

.overflow-hidden {
    overflow: hidden;
}

.close {
    @include inline-block;
    @include size(32px,32px);
    padding: 0;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    border: 0;
    background: rgba(0,0,0,0);
}

.lightbox-item {
    @include before;
    display: block;

    &:before {
        @include pos(0,0);
        right: 0;
        bottom: 0;
        transform: scale(0);
        transition: all .3s ease;
        background: $color__brand_light;
    }

    &:hover {
        &:before {
            transform: scale(1);
        }

        .lightbox-item__resize {
            opacity: 1;
        }
    }
}

.lightbox-item__resize {
    @extend %zi-2;
    @include pos(50%,50%);
    @include size(36px,36px);
    margin: -18px 0 0 -18px;
    transition: all .3s ease .3s;
    color: #fff;
    opacity: 0;
}

.steps {
    @extend %flex-center;
    justify-content: center;

    &.steps_bg {
        padding: 30px 30px 0;
        color: #fff;
        background: $color__brand_light;
    }   
}

.steps__counter {
    @include before;
    @include fw(36px);
    @include wh(36px,36px);
    @include font(20px,700);
    line-height: 36px;
    margin: 0 32px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #fff;
    background: $color__brand_light;
    transition: all .3s ease;

    &:before {
        @include wh(64px,1px);
        position: absolute;
        top: 50%;
        right: -64px;
        background: #fff;
    }

    &:last-child {
        &:before {
            display: none;
        }
    }

    &.active {
        color: $color__brand;
        background: #fff;
    }
}

.modal {
    &.modal_custom {
        .modal-content {
            border: 0;
            border-radius: 0;
        }
    }
}