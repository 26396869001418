.swiper__btn {
    @extend %zi-2;
    @extend %btn-default;
    @include size(22px,40px);
    position: absolute;
    top: 50%;
    margin: -20px 0 0;
    color: $color__brand;

    &.swiper__btn_prev {
        left: 0;
    }

    &.swiper__btn_next {
        right: 0;
    }
}